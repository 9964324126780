import React, { useState } from 'react';
import './App.css';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // For loader state

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const validateEmail = (email) => {
    // Simple email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  };

  const sendEmail = async (e) => {
    e.preventDefault();
  
    if (!validateEmail(inputValue)) {
      setMessage('Ugyldig e-postadresse. Vennligst skriv inn en gyldig e-post.');
      return;
    }
  
    setIsLoading(true);  // Set loading state
  
    const emailData = {
      to: inputValue,
      message: 'None',  // You can replace this with a custom message if needed
    };
  
    try {
      const response = await fetch('/.netlify/functions/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
  
      const responseData = await response.json();  // Parse response JSON
  
      if (response.ok) {
        setMessage('La oss starte reisen sammen');
      } else {
        setMessage(`Enrolment failed: ${responseData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Enrolment failed. Nettverksfeil.');
    } finally {
      setIsLoading(false);  // Reset loading state
    }
  };
  

  return (
    <div className="App">
      <header className="App-header">
        <p className="mainText" style={{ color: 'black' }}>
          Føler du at samfunnet har et strupetak på din frihet og dine muligheter til å utforske ditt potensiale?<br />
          Er du begavet teknologisk, men jobber for tørre selskaper uten evne til å jakte ambisjoner?<br />
          Blir du aktivt begrenset av feigere menneskers selvrettferdige moralske kompass?<br /><br />
          Kompliserte tider krever kompliserte aktører. Bli med og knus systemet, ikke fall bak når vi bryter ned hyklernes murer!<br /><br />
        </p>
        <form onSubmit={sendEmail}>
          <input
            className="input"
            type="email"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="E-post"
            required
          />
          <button className="button" type="submit" disabled={isLoading}>
            {isLoading ? 'Sender...' : 'Meld deg'}
          </button>
        </form>
        {message && <p>{message}</p>}
      </header>
    </div>
  );
}

export default App;
